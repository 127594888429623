<template>
  <div class="container">
    <div class="innerWrapper">
      <media :query="{minWidth: 769}">
        <div class="aside__wrapp">
          <div class="content-aside__wrapper">
            <TeamsCount :curr-teams="TOURNAMENT.attributes.participants_count" :max-teams="TOURNAMENT.attributes.count_of_teams"/>
            <div class="content-registration existing_entry" v-if="TOURNAMENT.participant_entry !== null">
              <span class="s1">Вы учавствуете в данном турнире</span>
            </div>

            <template>
              <div class="content-registration" v-if="getTournamentStartedAtBySeconds(TOURNAMENT) > TOURNAMENT.attributes.current_timestamp">
                <div class="s1">До начала турнира</div>
                <vue-countdown-timer
                    :start-time="TOURNAMENT.attributes.current_timestamp"
                    :end-time="getTournamentStartedAtBySeconds(TOURNAMENT)"
                    :seconds-txt="null"
                    :day-txt="null"
                    end-text=""
                    @end_callback="updateTournament()"
                >
                </vue-countdown-timer>
              </div>
              <div class="content-registration" v-else>
                <div class="s1">{{ getTournamentStatus(TOURNAMENT) }}</div>
              </div>
            </template>


<!--            v-if="isUserCanDoIt(TOURNAMENT)"-->
            <template v-if="participateButtonStatus">
              <vue-countdown-timer
                  v-show="false"
                  :start-time="TOURNAMENT.attributes.current_timestamp"
                  :end-time="getTournamentCheckInAtBySeconds(TOURNAMENT)"
                  :seconds-txt="null"
                  :day-txt="null"
                  end-text=""
                  @end_callback="hideRegBtn(TOURNAMENT)"
              >
              </vue-countdown-timer>
              <button v-if="showRegBtn" class="filled__yellow" @click="participateInTournament(TOURNAMENT)">
                Участвовать в турнире
              </button>
            </template>
          </div>
          <div class="filled__grey decline" @click="declineEntry" v-if="canUserDecline(TOURNAMENT)">Отменить участие</div>
        </div>
      </media>

      <div class="content-block__wrapper">
        <div class="content-block">
          <div class="content-block__title">{{ TOURNAMENT.game.attributes.name }}</div>
          <div class="content-block__content">
            <!--<div class="content-block__string">
              <div class="s1">Игровая платформа</div>
              <div class="s2">Платформа</div>
            </div>-->
            <div class="content-block__string">
              <div class="s1">Формат турнира</div>
              <div class="s2">{{ getFormat(TOURNAMENT) }}</div>
            </div>
            <div class="content-block__string">
              <div class="s1">Формат участия</div>
              <div class="s2">{{ getPlayersFormat(TOURNAMENT) }}</div>
            </div>
            <div class="content-block__string">
              <div class="s1">Страна</div>
              <div class="s2">{{ TOURNAMENT.attributes.country }}</div>
            </div>
            <div class="content-block__string mb30">
              <div class="s1">Замен за матч</div>
              <div class="s2">{{ TOURNAMENT.attributes.player_replacement_in_team }}</div>
            </div>
            <div class="content-block__string">
              <router-link :to="{name: 'tournaments.rules'}" class="rules">Прочитать правила</router-link>
            </div>
          </div>
        </div>

        <div class="content-block">
          <div class="content-block__title">награды</div>
          <div class="content-block__content">
            <div class="content-block__string mb15">
              <div class="s1">Весь призовой фонд</div>
              <div class="s2">{{ TOURNAMENT.attributes.prize_fund }}</div>
            </div>
            <div class="content-block__string">
              <div class="s1">1 место</div>
              <div class="s2">{{ TOURNAMENT.attributes.first_prize }}</div>
            </div>
            <div class="content-block__string">
              <div class="s1">2 место</div>
              <div class="s2">{{ TOURNAMENT.attributes.second_prize }}</div>
            </div>
            <div class="content-block__string mb15">
              <div class="s1">3 место</div>
              <div class="s2">{{ TOURNAMENT.attributes.third_prize }}</div>
            </div>
          </div>
        </div>

        <div class="content-block">
          <div class="content-block__title">О турнире</div>
          <div class="content-block__content">
            <div class="text-desc" v-html="TOURNAMENT.attributes.description"></div>
          </div>
        </div>
        <!--<div class="content-block">
          <div class="content-block__title premium"><span class="big">Требования</span>
            <span class="small">Премиум</span>
          </div>
          <div class="content-block__content">
            <div class="content-block__string mb15">
              <div class="s1">Доступные страны</div>
              <div class="s2">
                <div class="flag-list">
                  <div class="flags">
                    <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0)">
                        <path d="M24 0.5H0V5.83333H24V0.5Z" fill="white"/>
                        <path d="M24 5.83398H0V11.1673H24V5.83398Z" fill="#061B79"/>
                        <path d="M24 11.166H0V16.4993H24V11.166Z" fill="#C23729"/>
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect y="0.5" width="24" height="16" rx="2" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                    <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0)">
                        <path d="M24 0.5H0V8.39333H24V0.5Z" fill="#005BBB"/>
                        <path d="M24 8.39453H0V16.5012H24V8.39453Z" fill="#FFD500"/>
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect y="0.5" width="24" height="16" rx="2" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                    <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0)">
                        <path d="M24 8.5H0V16.5H24V8.5Z" fill="#DC143C"/>
                        <path d="M24 0.5H0V8.5H24V0.5Z" fill="white"/>
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect y="0.5" width="24" height="16" rx="2" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                    <span>+5</span>
                  </div>
                  <div class="show-flags">Развернуть</div>
                </div>
              </div>
            </div>
            <div class="content-block__string">
              <div class="s1">Плата за вход</div>
              <div class="s2">{{ tournament.registrationPrice }}</div>
              <div v-if="tournament.currency === 'dollar'">$</div>
            </div>
          </div>
        </div>-->

        <div class="content-block">
          <div class="content-block__title">График</div>
          <div class="content-block__content">
            <div class="content-block__string mb15">
              <media :query="{minWidth: 769}">
                <div class="s1" v-html="'Регистрация на турнир<br>начинается'"/>
              </media>
              <media :query="{maxWidth: 768}">
                <div class="s1">Регистрация на турнир начинается</div>
              </media>
              <div class="s2">{{ getStartRegTime(TOURNAMENT) }} {{ getTimezone() }}</div>
            </div>
            <div class="content-block__string mb15">
              <media :query="{minWidth: 769}">
                <div class="s1" v-html="'Регистрация на турнир<br>заканчивается'"/>
              </media>
              <media :query="{maxWidth: 768}">
                <div class="s1">Регистрация на турнир заканчивается</div>
              </media>
              <div class="s2">{{ getEndRegTime(TOURNAMENT) }} {{ getTimezone() }}</div>
            </div>
            <div class="content-block__string">
              <div class="s1">Начинается</div>
              <div class="s2">{{ getStartedTime(TOURNAMENT) }} {{ getTimezone() }}</div>
            </div>
            <!--<div class="divider"></div>
            <div class="content-block__string">
              <div class="s1">Число групп</div>
              <div class="s2">{{ tournament.groupsAmount }}</div>
            </div>
            <div class="content-block__string">
              <div class="s1">Число раундов</div>
              <div class="s2">{{ tournament.roundsAmount }}</div>
            </div>
            <div class="content-block__string">
              <div class="s1">Раунд 1</div>
              <div class="s2">{{ tournament.startFirstRound | regTime }} {{ getTimezone() }}</div>
            </div>
            <div class="content-block__string">
              <div class="s1">Раунд 2</div>
              <div class="s2">{{ tournament.startSecondRound | regTime }} {{ getTimezone() }}</div>
            </div>
            <div class="content-block__string mb30">
              <div class="s1">Раунд 3</div>
              <div class="s2">{{ tournament.startThirdRound | regTime }} {{ getTimezone() }}</div>
            </div>
            <div class="wrapper">
              Показать полный график
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 6L8 10L12 6" stroke="white" stroke-linejoin="round"/>
              </svg>
            </div>-->
          </div>
        </div>
      </div>
    </div>
    <tournament-registration :tournament="TOURNAMENT"
                             :composition="currentComposition" v-if="isModalOpened" @closeModal="isModalOpened = false"/>
    <simple-text-modal
        v-if="isSimpleModalOpen"
        :configForSimpleTextModal="configForSimpleTextModal"
        @closeSimpleModal="isSimpleModalOpen = false"/>
  </div>
</template>

<script>
import getTimezone from "@/otherJS/getTimezone";
import Media from 'vue-media';
import TeamsCount from '../../UIElements/TeamsCount';
import SimpleTextModal from "@/components/modals/simple-text-modal";
import {mapActions, mapGetters} from 'vuex';
import TournamentRegistration from "@/components/modals/tournament-registration/tournament-registration";
import {tournamentInfoMixin} from "@/otherJS/mixins/tournamentInfoMixin";

export default {
  name: "tournamentInfo",
  components: {TournamentRegistration, SimpleTextModal, Media, TeamsCount},
  data() {
    return {
      maxTeams: undefined,
      showRegBtn: true,
      isSimpleModalOpen: false,
      configForSimpleTextModal: {
        text: 'Ваша заявка успешно подана!',
        status: 'default',
        timeoutSeconds: 3,
      }
    }
  },
  mixins: [getTimezone, tournamentInfoMixin],
  computed: {
    ...mapGetters([
      'TOURNAMENT',
      'PARTICIPANTS',
      'AUTH',
      'USER',
      'USER_MANAGED_TEAMS'
    ]),
  },
  methods: {
    ...mapActions([
      'IS_ENTRY_CREATED',
      'CREATE_TOURNAMENT_ENTRIE',
      'DECLINE_ENTRY'
    ]),
    openRegistrationModal() {
      this.$emit('registerMe')
    },

  },
  mounted() {

  }
}
</script>

<style>
</style>
