<template>
  <div class="content-teams">
    <div class="teams-label">
      <svg width="32" height="32" viewBox="0 0 120 120">
        <circle cx="60" cy="60" r="54" fill="none" stroke="#B0B0B0" stroke-width="9"/>
        <circle ref="circle" class="painted_circle" stroke-linecap="round"  cx="60" cy="60" r="54" stroke="#E2FF31" stroke-width="9" fill="none" :stroke-dasharray="strokeDasharray" :stroke-dashoffset="strokeDiff" stroke-mitterlimit="0" />
      </svg>
      <svg class="s2" width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.95002 0C4.08018 0 3.37502 0.809647 3.37502 1.80838C3.37502 2.80713 4.08018 3.61674 4.95002 3.61674C5.81987 3.61674 6.52502 2.80713 6.52502 1.80838C6.52502 0.809647 5.81987 0 4.95002 0ZM9.00002 0C7.88165 0 6.97502 0.910829 6.97502 2.03443C6.97502 3.15802 7.88165 4.06884 9.00002 4.06884C10.1184 4.06884 11.025 3.15802 11.025 2.03443C11.025 0.910829 10.1184 0 9.00002 0ZM13.05 0C12.1802 0 11.475 0.809647 11.475 1.80838C11.475 2.80713 12.1802 3.61674 13.05 3.61674C13.9199 3.61674 14.625 2.80713 14.625 1.80838C14.625 0.809647 13.9199 0 13.05 0ZM3.84612 4.05471C1.82112 4.6056 0.900024 4.79018 0.900024 8.82995L2.00393 9.38092C2.37211 7.7283 2.37531 7.36065 2.5594 7.36065C2.74349 7.36065 2.73966 7.912 3.10784 9.74827C3.27137 9.77544 3.44373 9.79975 3.62112 9.81889C3.68998 7.93373 3.96549 6.79078 4.56331 6.01849C5.25927 5.11953 6.28619 4.87677 7.5094 4.54215C7.10899 4.34401 6.62683 4.21057 6.05393 4.05471C5.31757 4.6056 4.58248 4.6056 3.84612 4.05471ZM11.9461 4.05471C11.3671 4.21223 10.8794 4.33879 10.4766 4.53506C11.7066 4.87195 12.7404 5.12112 13.4367 6.05381C14.0131 6.82599 14.2915 7.96537 14.3719 9.81889C14.5517 9.79977 14.7266 9.77578 14.8922 9.74827C15.2604 7.912 15.2566 7.36065 15.4406 7.36065C15.6247 7.36065 15.6279 7.7283 15.9961 9.38092L17.1 9.01362C17.1 4.79024 16.1789 4.6056 14.1539 4.05471C13.4176 4.6056 12.6825 4.6056 11.9461 4.05471ZM7.65002 4.973C5.17502 5.65115 4.05002 5.87718 4.05002 10.8502L5.40002 11.5283C5.85002 9.49391 5.85002 9.04183 6.07502 9.04183C6.30002 9.04183 6.30002 9.71999 6.75002 11.9805C8.10002 12.2065 9.90002 12.2065 11.25 11.9805C11.7 9.71999 11.7 9.04183 11.925 9.04183C12.15 9.04183 12.15 9.49391 12.6 11.5283L13.95 11.0763C13.95 5.87718 12.825 5.65115 10.35 4.973C9.45002 5.65115 8.55002 5.65115 7.65002 4.973Z"
            fill="#E2FF31"/>
      </svg>
    </div>
    <span class="s1">Команды:</span>
    <span class="s2">
            <span class="s3">{{ currTeams }}</span> / <span class="s4">{{ maxTeams }}</span>
          </span>
  </div>
</template>

<script>
export default {
name: "TeamsCount",
  props: {
    currTeams: Number,
    maxTeams: Number
  },
  data() {
    return {
      strokeDasharray: 0,
      strokeDashoffset: 0,
      strokeDiff: 0
    }
  },
  methods: {
    checkFill: function(length) {
      this.strokeDasharray = length;
      const currentCircleLengthPercent = (this.currTeams * 100) / this.maxTeams;
      this.strokeDashoffset = ((length * currentCircleLengthPercent) / 100);
      this.strokeDiff = this.strokeDasharray - this.strokeDashoffset;
    }
  },
  mounted() {
    this.checkFill(this.$refs.circle.getTotalLength())
  }
}
</script>

<style scoped>
.painted_circle {
  transform: rotate(90deg);
  transform-origin: center;
}
</style>